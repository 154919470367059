/* eslint no-underscore-dangle: ["error", { "allow": ["_renderItem"] }]*/
const SearchSuggestor = require('./search-suggestor');

function registerTypeahead(jq) {
  if (('ui' in jq === false)
    || (typeof jq.ui.autocomplete !== 'function')) {
    console.error('Typeahead: JQuery autocomplete not loaded.');
    return;
  }
  const options = {};
  if (!/\.lexmark\.com$/.test(window.location.hostname)) {
    options.host = 'https://www.lexmark.com';
  }
  const suggestor = new SearchSuggestor(window.location.href, options);
  const searchInputs = jq('#search-large, #search-desktop, #search-mobile');
  jq.widget('custom.lxkAutocomplete', jq.ui.autocomplete, {
    _renderItem: function render(ul, item) {
      const hilitedLabel =
        item.label.replace(
          new RegExp(
            '(?![^&;]+;)(?!<[^<>]*)('
            + jq.ui.autocomplete.escapeRegex(this.term)
            + ')(?![^<>]*>)(?![^&;]+;)'
            , 'gi'),
          '<strong>$1</strong>');
      const li = jq('<li>');
      if (item.passthru) {
        li.addClass('status');
      }
      return li
        .append('<div><span>' + hilitedLabel + '</span></div>')
        .appendTo(ul);
    }
  });
  searchInputs.each((i, el) => {
    const $input = jq(el);
    $input.lxkAutocomplete({
      source: (request, response) => suggestor.getSuggestions(request, response),
      minLength: suggestor.minLength,
      select: (event, ui) => {
        if (ui.item.url) {
          const url = Array.isArray(ui.item.url) ?
            ui.item.url[0] :
            ui.item.url;
          window.location = url;
        } else {
          $input.val(ui.item.value);
          $input.closest('form').submit();
        }
        return true;
      }
    });
  });
}

function init(jq) {
  jq(() => registerTypeahead(jq));
}

if (typeof define === 'function' && typeof define.amd === 'object') {
  define(['jquery', 'jquery-ui-autocomplete'], init);
} else {
  init(jQuery);
}

