const I18n = require('../lib/i18n');

class SearchSuggestor {
  constructor(url, options) {
    this.options = Object.assign({}, {
      minLength: 3,
      host: '',
      maxSuggestions: 7,
      solrRequestHandler: '/model-typeahead',
      fields: 'title_t,type,thumbnail_s,url,cugs_t,pagetype_s,partnumber_s'
    }, options);
    const [, lang, country] = /\/([a-z]{2})_([A-Z]{2})\b/i.exec(url) || [];
    this.lang = lang || 'en';
    this.country = (country || 'US').toUpperCase();
    this.locale = `${this.lang}_${this.country}`;
    this.site = /^https:\/\/partnernet/.test(url) ? 'partnernet' : 'lexmark';
    this.i18n = new I18n(require('./language.json'), `${this.lang}_${this.country}`);
  }

  get minLength() {
    return this.options.minLength;
  }

  /**
   * Build the query for solr typeahead suggestions.
   * @param {string} term The search term (like MX6)
   */
  buildQuery(term) {
    return `${this.options.host}/bin/${this.site}/solr/search.json?` +
      `locale=${this.lang}_${this.country}` +
      `&start=0&rows=${this.options.maxSuggestions}` +
      `&qt=${this.options.solrRequestHandler}` +
      `&fields=${this.options.fields}` +
      `&q=${term}`;
  }

  /**
   * Get suggestions from an AJAX request.
   * @param {Object} request The request object from JQueryUI
   *    autocomplete. Contains with a single term property, which
   *    refers to the value currently in the text input.
   * @param {function} response A response callback, which expects
   *    a single argument: the data to suggest to the user.
   */
  getSuggestions({ term } = {}, response) {
    const request = new XMLHttpRequest();
    request.open('GET', this.buildQuery(term), true);
    let suggestions = [];
    const minmatch = term.substring(0, this.options.minLength).toUpperCase();
    request.onload = () => {
      if (request.status >= 200 && request.status < 400) {
        try {
          const result = JSON.parse(request.responseText);

          suggestions = result.response.docs
            .filter(hit => (
              ((hit.pagetype_s == 'device') || (hit.pagetype_s == 'supply'))
              && hit.title_t.toUpperCase().includes(minmatch))
            )
            .map((hit) => ({
              label: hit.title_t,
              url: hit.url,
              type: hit.pagetype_s,
              thumbnail: hit.thumbnail_s,
              partnumber: hit.partnumber_s
            }));
          const suggestedDevices = suggestions.filter(sugg => (sugg.type == 'device'));
          if (suggestedDevices.length == 1) {
            const base = suggestedDevices[0];
            var modelStr = base.label;
            // Try to pull model name out from the more recent long formatted marketing names
            // For exmample: Lexmark Color All-in-One 2-series (MC3224i)
            var longNameModelMatch = base.label.match(/\(([A-Za-z0-9]+)\)/);
            if (longNameModelMatch != undefined && longNameModelMatch.length >= 2) {
              modelStr = longNameModelMatch[1];
            }
            var name = modelStr.replace(/^Lexmark /, '').replace(/[a-z]+$/, '');
            suggestedDevices.push({
              url: base.url + '#supplies',
              label: this.i18n.get('{0} Supplies', base.label)
            });
            suggestedDevices.push({
              url: base.url + '#accessories',
              label: this.i18n.get('{0} Accessories', base.label)
            });
            suggestedDevices.push({
              url: 'https://support.lexmark.com/' + this.locale.toLowerCase() + '/drivers-downloads.html?q=' + name,
              label: this.i18n.get('{0} Drivers & Downloads', base.label)
            });
            suggestedDevices.push({
              url: 'https://infoserve.lexmark.com/ids/ifc/ids_home.aspx?queryProduct=' + name + '&loc=' + this.locale,
              label: this.i18n.get('{0} Interactive User Guide', base.label)
            });
            suggestedDevices.push({
              url: `http://md.lexmark.com?dest=support&lang=${this.lang}&country=${this.locale}&partnumber=${base.partnumber}`,
              label: this.i18n.get('{0} Support', base.label)
            });
            suggestions = suggestedDevices;
          }
        } catch (e) {
          console.error(`Typeahead data err: ${e.name}: ${e.message}`);
        }
      } else {
        console.error(`Typeahead connection err: ${request.status}-${request.statusText}`);
      }
      response(suggestions);
    };

    request.onerror = () => {
      console.error('Typeahead request error.');
      response(suggestions);
    };

    request.send();
  }
}

module.exports = SearchSuggestor;
