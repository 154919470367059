/* eslint-env node */
class I18n {
  /**
   * Makes an I18 Object for providing translated strings.
   * @param {Object} dictionary A Translation dictionary object.
   * @param {string} locale The locale code `en_us`, `en_US`, `en-us` or `en`
   */
  constructor(dictionary, locale) {
    this.lang = locale || '';
    if (typeof dictionary != 'object') {
      throw new Error('Missing dictionary');
    }
    this.lang = this.lang.toLowerCase().replace('-', '_');
    if (!Object.hasOwnProperty.call(dictionary, this.lang)) {
      this.lang = this.lang.substring(0, 2);
      if (!Object.hasOwnProperty.call(dictionary, this.lang)) {
        this.lang = 'en';
      }
    }
    if (!Object.hasOwnProperty.call(dictionary, this.lang)) {
      throw new Error(`Cannot find dictionary for ${locale}`);
    }
    this.dict = dictionary[this.lang];
  }

  /**
   * Insert values into a string using zero-based insertion
   * markers.  insert('Hello {0}', 'World')
   * @param {String} text A string with insertion markers.
   * @param {*} values
   */
  insert(text, ...values) {
    let result = text;
    for (let i = 0; i < values.length; i++) {
      result = result.replace(`{${i}}`, values[i]);
    }
    return result;
  }

  /**
   * Lookup a localization string.
   * @param {string} key The key to lookup
   * @param {string} values The values to insert in string
   */
  get(key, ...values) {
    let result = key || '';
    if (Object.hasOwnProperty.call(this.dict, key)) {
      result = this.dict[key];
    }
    return (values.length) ? this.insert(result, values) : result;
  }
}

module.exports = I18n;
